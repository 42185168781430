import React from 'react'
import { MeetingContactPage } from '../../../components/MeetingContactPage'

import { SHOGUN_REQUEST } from '../../../../content/data/contactPages'

const PlanShogunRequest = () => {

    return (
        <>
            <MeetingContactPage pageData={SHOGUN_REQUEST} />
        </>
    )
}

export default PlanShogunRequest
